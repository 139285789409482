import common from './common.json';
import addressBook from './addressBook.json';
import admin from './admin.json';
import assets from './assets.json';
import approval from './approval.json';
import auth from './auth.json';
import currency from './currency.json';
import merchantPlatform from './merchantPlatform.json';
import merchantCategories from './merchantCategories.json';
import merchantTip from './merchantTip.json';
import profile from './profile.json';
import retirements from './retirements.json';
import SAShutdown from './SAShutdown.json';
import sell from './sell.json';
import toast from './toast.json';
import verification from './verification.json';
import wallet from './wallet.json';

export default {
  addressBook,
  admin,
  approval,
  assets,
  auth,
  common,
  currency,
  merchantPlatform,
  merchantCategories,
  merchantTip,
  profile,
  retirements,
  SAShutdown,
  sell,
  toast,
  verification,
  wallet,
};
